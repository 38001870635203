import { useStaticQuery, graphql } from 'gatsby';
import { SanityNavigationItem } from 'graphql-types';

interface Categories {
  categories: SanityNavigationItem[];
}

const useBlogCategories = (): Categories => {
  const { categories } = useStaticQuery(
    graphql`
      query getBlogCategories {
        categories: sanityNavigation(_id: { eq: "blog-category-navigation" }) {
          navigationItem {
            title
            path
            id: _key
          }
        }
      }
    `
  );

  return {
    categories: categories.navigationItem
  };
};

export default useBlogCategories;
