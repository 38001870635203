import React, { ReactNode } from 'react';
import { H1, Text, Box } from '@parsleyhealth/cilantro-ui';
import BlogHeroLayout from './blog-hero-layout';
import { SanityBasicImage } from 'graphql-types';

interface BlogHeroProps {
  title: string;
  image?: SanityBasicImage;
  description?: string | ReactNode;
}

const BlogHero = ({ title, description, image }: BlogHeroProps) => {
  return (
    <BlogHeroLayout image={image}>
      <Box py="m">
        <H1 color="white" textAlign="center">
          {title.replace('&amp;', '&')}
        </H1>
        {description && (
          <Text color="white" textAlign="center" variant="pLarge">
            {description}
          </Text>
        )}
      </Box>
    </BlogHeroLayout>
  );
};

export default BlogHero;
