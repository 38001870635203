import React from 'react';
import { Box, getResponsiveProp } from '@parsleyhealth/cilantro-ui';
import { SanityBlogPost, SanityBlogVideo } from 'graphql-types';
import BlogPostCard from './blog-post-card';
import BlogAuthor from './BlogAuthor';
import { getAuthorUrl } from '../../lib/blog-utils';
import SanityImage from '../NewSanityImage';
import BlockContent from '../../components/BlockContent';
import truncateTextBlock from '../../lib/truncate-text-blocks';

type Post = SanityBlogPost | SanityBlogVideo;

interface BlogPostGalleryProps {
  posts: Post[];
  pathPrefix?: string;
  simpleCard?: boolean;
}

const BlogPostGallery = ({
  posts,
  pathPrefix = 'blog',
  simpleCard
}: BlogPostGalleryProps) => {
  return (
    <Box display="flex" flexWrap="wrap">
      {posts.map((post: Post) => (
        <Box
          mb="l"
          key={post.id}
          width={['100%', '100%', '100%', '50%']}
          pr={['none', 'none', 'none', 's']}
        >
          <BlogPostCard
            simpleCard={simpleCard}
            url={`/${pathPrefix}/${post.slug.current}`}
            title={post.title}
            category={post?.categories[0]?.title}
            description={
              <BlockContent blocks={truncateTextBlock(post?.content, 200)} />
            }
            image={
              post?.image && (
                <SanityImage
                  image={post?.image}
                  imageSizes={getResponsiveProp('100vw', '33vw')}
                  coverContainer
                />
              )
            }
            author={
              post.author ? (
                <BlogAuthor
                  name={post?.author?.authorByLine}
                  url={getAuthorUrl(post?.author?.slug.current)}
                  image={post?.author?.profileImage}
                  role={post?.author?.role}
                  size="s"
                />
              ) : null
            }
          />
        </Box>
      ))}
    </Box>
  );
};

export default BlogPostGallery;
