import React from 'react';
import { Box } from '@parsleyhealth/cilantro-ui';
import { SanityBasicImage, SanityBlogPost, SanityBlock } from 'graphql-types';
import { WebsiteWrapper } from '@parsleyhealth/cilantro-ui';
import { useBlogConfig } from '../../hooks/use-blog-config';
import useFeaturedBlogPosts from '../../hooks/use-featured-blog-posts';
import BlogLayout from './blog-layout';
import BlogSimpleHero from './blog-simple-hero';
import BlogGrid from './blog-grid';
import BlogSectionTitle from './blog-section-title';
import BlogPostGallery from './blog-post-gallery';
import BlogSidebarCard from './blog-sidebar-card';
import BlogFeaturedPosts from './BlogFeaturedPosts';
import BlogCategories from './BlogCategories';
import { BlogBanner } from './blog-banner';
import BlockContent from '../BlockContent';

interface BlogPageGenericTemplateProps {
  description?: SanityBlock[];
  image?: SanityBasicImage;
  popularPosts?: SanityBlogPost[];
  posts: SanityBlogPost[];
  title: string;
}

export const BlogPageGenericTemplate = ({
  description,
  image,
  popularPosts,
  posts,
  title
}: BlogPageGenericTemplateProps) => {
  const { sidebarTitle, SidebarWhatIsPH, Sidebar150Off } = useBlogConfig();
  const { featuredPosts } = useFeaturedBlogPosts();

  const hasPopularPosts = popularPosts && popularPosts.length;

  return (
    <BlogLayout paddingTop={false}>
      <BlogSimpleHero
        title={title}
        description={
          description ? <BlockContent blocks={description} /> : undefined
        }
        image={image}
      />
      <Box>
        <WebsiteWrapper>
          <BlogGrid>
            <Box pb="m">
              <BlogSectionTitle mb="l">Latest from {title}</BlogSectionTitle>
              <BlogPostGallery posts={posts} />
            </Box>
            <Box pl={['none', 'none', 'none', 'l']}>
              {Sidebar150Off && (
                <>
                  <BlogSectionTitle mb="l">{sidebarTitle}</BlogSectionTitle>
                  <BlogSidebarCard card={Sidebar150Off} prefix="siderail-1" />
                </>
              )}
              <br />
              <BlogFeaturedPosts
                posts={hasPopularPosts ? popularPosts : featuredPosts}
                title={hasPopularPosts ? 'Popular Posts' : 'Featured Posts'}
              />
              <br />
              <BlogCategories />
              <br />
              {SidebarWhatIsPH && (
                <>
                  <BlogSidebarCard card={SidebarWhatIsPH} prefix="siderail-2" />
                </>
              )}
            </Box>
          </BlogGrid>
        </WebsiteWrapper>
      </Box>
      <BlogBanner />
    </BlogLayout>
  );
};
