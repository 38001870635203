import React from 'react';
import { Box, Text, Link } from '@parsleyhealth/cilantro-ui';
import styled from '@emotion/styled';
import useBlogCategories from '../../hooks/use-blog-categories';
import BlogSectionTitle from './blog-section-title';

const LinkContainer = styled(Link)`
  text-decoration: none;
`;

const Chevron = styled(Box)`
  position: absolute;
  right: 0;
  top: 47%;
  border-left-width: 2px;
  border-bottom-width: 2px;
  border-right-width: 0;
  border-top-width: 0;
  border-style: solid;
  width: 10px;
  height: 10px;
  transform-origin: center;
  transform: rotate(225deg);
`;

const BlogCategories = () => {
  const { categories } = useBlogCategories();
  const isLast = (index: number) => index >= categories.length - 1;
  return (
    <Box>
      <BlogSectionTitle mb="m">Browse by Category</BlogSectionTitle>
      <Box>
        {categories.map((category: any, index: number) => (
          <>
            <Box py="s" key={category.id} position="relative">
              <LinkContainer href={category.path}>
                <Text variant="pLarge" textDecoration="none">
                  {category.title.replace('&amp;', '&')}
                </Text>
              </LinkContainer>
              <Chevron borderColor="leafyGreen" />
            </Box>
            {!isLast(index) && (
              <Box bg="greyLightBorder" m="none" height="1px" width="100%" />
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};

export default BlogCategories;
