import React, { FC } from 'react';
import { Box, WebsiteWrapper } from '@parsleyhealth/cilantro-ui';
import { SanityBasicImage } from 'graphql-types';
import SanityImage from '../NewSanityImage';

interface BlogHeroLayoutProps {
  image: SanityBasicImage;
  py?: 's' | 'm' | 'l' | 'xl' | 'xxl';
  children?: any;
}

const BlogHeroLayout: FC<BlogHeroLayoutProps> = ({
  children,
  py = 'xl',
  image
}) => {
  return (
    <Box
      backgroundColor="altHeroBackground"
      display="flex"
      minHeight="60px"
      overflow="hidden"
      position="relative"
    >
      <Box
        className="bg"
        position="absolute"
        top="0"
        bottom={0}
        width="100%"
        overflow="hidden"
        zIndex={0}
      >
        {image && <SanityImage image={image} coverContainer />}
      </Box>
      <Box
        position="absolute"
        top="0"
        bottom={0}
        width="100%"
        overflow="hidden"
        zIndex={1}
        bg="overlayBlack"
      />
      <Box zIndex={2} py={py} width="100%">
        <WebsiteWrapper>{children}</WebsiteWrapper>
      </Box>
    </Box>
  );
};

export default BlogHeroLayout;
