import { useStaticQuery, graphql } from 'gatsby';
import { SanityBlogPost } from 'graphql-types';

interface FeaturedPosts {
  featuredPosts: SanityBlogPost[];
}

const useFeaturedBlogPosts = (): FeaturedPosts => {
  const {
    sanityBlogConfig: { featuredPosts }
  } = useStaticQuery(
    graphql`
      query getFeaturedBlogPosts {
        sanityBlogConfig {
          featuredPosts {
            _id
            id
            slug {
              current
            }
            title
            author {
              ...BlogPostAuthor
            }
          }
        }
      }
    `
  );

  return {
    featuredPosts: featuredPosts.sort(() => Math.random() - 0.5) ?? []
  };
};

export default useFeaturedBlogPosts;
