import React from 'react';
import { graphql } from 'gatsby';
import { SanityCondition, SanityBlogPost } from 'graphql-types';
import { BlogPageGenericTemplate } from '../components/Blog/blog-page-generic-template';
import { default as SEO } from '../components/SEO';

interface BlogConditionTemplateProps {
  data: {
    condition: SanityCondition;
    posts: {
      edges: {
        node: SanityBlogPost;
      }[];
    };
  };
}

const BlogConditionTemplate = ({ data }: BlogConditionTemplateProps) => {
  const { condition } = data;
  const { metaTitle, metaDescription, metaImage, noindex } = condition;

  if (!condition) {
    return null;
  }

  const posts = data.posts.edges.map(({ node }) => node).slice(0, 10) || [];

  return (
    <>
      <SEO
        pageTitle={metaTitle ? metaTitle : condition.title}
        pageDescription={metaDescription && null}
        pageImage={metaImage ?? null}
        noindex={noindex}
      />
      <BlogPageGenericTemplate
        title={condition.title}
        description={condition.description}
        posts={posts}
      />
    </>
  );
};

export const query = graphql`
  query BlogConditionQuery($sanityID: String) {
    condition: sanityCondition(_id: { eq: $sanityID }) {
      title
      description: _rawDescription
    }
    posts: allSanityBlogPost(
      filter: {
        conditions: { elemMatch: { _id: { eq: $sanityID } } }
        slug: { current: { ne: null } }
      }
      sort: { order: DESC, fields: publishedDate }
    ) {
      edges {
        node {
          ...BlogPostCardInfo
        }
      }
    }
  }
`;

export default BlogConditionTemplate;
